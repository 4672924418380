import React, { useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap"
import "../../CSS/Contact.css"
import {
  FaFacebook,
  FaInstagram,
} from "react-icons/fa";
import { useApp } from "../../Contexts/appContext";
import { toast } from "react-toastify";
import { contactUs } from '../../Services/Profile/profileService';
import swal from 'sweetalert';
import FaTwitter from '../../Assests/imgs/twitterlogo.png'
import Footer from "../FooterPages/Footer";
import Header from "../NavBar/Header";


const ContactArea = () => {

  const { recaptchaToken, setRecaptchaToken } = useApp()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [messageErr, setMessageErr] = useState('');

  const nameRegex = /^[A-Za-z\s]+$/
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/


  window.handleCaptchaSuccess = handleCaptchaSuccess
  function handleCaptchaSuccess(token) {
    setRecaptchaToken(token)
  }

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js"
    script.async = true
    script.defer = true
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, []);

  const submitContactDetails = async () => {
    // console.log("name ", name);
    // console.log("email", email);
    // console.log("message", message)
    // console.log("recapta token ", recaptchaToken);

    setNameErr(!nameRegex.test(name) ? "Please enter a valid name" : '')
    setEmailErr(!emailRegex.test(email) ? "Please enter a valid email" : '')
    setMessageErr(!message ? "Please enter the message" : "");
    if (!nameRegex.test(name) || !emailRegex.test(email)) {
      // console.log("in err")
      return
    }
    if (message) {
      // Form validation and submission logic when reCAPTCHA is successful and message is not empty
      const formData = {
        name: name,
        email: email,
        message: message
      }
      const response = await contactUs(formData)
      if (response.status === 200) {
        // toast.success("Successfully submitted")
        setName('');
        setEmail('');
        setMessage('');
        swal({
          text: "Successfully submitted",
          icon: 'success',
          buttons: {
            cancel: 'Close',
            confirm: {
              text: 'Okay',
              className: 'request-button',
            },
          },
        })
      } else {
        swal({
          text: 'Unable to send',
          icon: 'error',
          dangerMode: true,
        });
      }
    } else {
      setMessageErr("Please verify that you are not a robot and provide a message.")
    }

  }

  return (
    <>
      <Header />
      <div className="bottomspacedash">
        <div className="cover-image-container">
          {/* <div className="overlay"></div> */}
          <img
            className="cover-image"
            src="https://wbwp.s3.us-west-1.amazonaws.com/s3fs-public/inline-images/bride-with-flowing-blue-dress-and-groom-by-glacier-lake-edwin-tan-photography.jpg?VersionId=H_NLtXGmk3l5lVcWGLkWCj1rGv44ZEQy"
            alt="Cover"
          />
          <div className="cover-text">
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className="container contact-container">
          <div className="row mt-2 py-2">
            <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
              <div className="row">
                <div className="col-12">
                  <h2 className="contact-topic-2">Any questions or comments?</h2>
                  <h2 className="contact-topic-2">feel free to contact us</h2>
                </div>
                <div className="col-12 my-4">
                  <p className="contact-paragraph mb-4">
                    We are dedicated to facilitating individuals from the Tamil
                    community worldwide in their search for the perfect life
                    partner. We appreciate your interest in our services and
                    encourage you to explore our site to find your ideal match. If
                    you have any questions or comments? We love to hear from you
                    all.
                  </p>
                  <p className="contact-paragraph">Email: support@vivaakam.com</p>
                  <p className="contact-paragraph">
                    Contact Number/ Whatsapp: +94 75 683 3563
                  </p>
                </div>
                <div className="col-12">
                  {/* <IconContext.Provider value={{ size: "2em" }}> */}
                  <Button variant="">
                    <a
                      className="socialmedia-icons"
                      href="https://www.facebook.com/profile.php?id=100092653369059"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook />
                    </a>
                  </Button>
                  <Button variant="">
                    {/* <a
                    className="socialmedia-icons"
                    href="https://twitter.com/vivaakam"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {FaTwitter }
                  </a> */}
                    <a className="socialmedia-icons" href="https://twitter.com/vivaakam" target="_blank">
                      <img src={FaTwitter} height='15px' />
                    </a>
                  </Button>
                  <Button variant="">
                    <a
                      className="socialmedia-icons"
                      href="https://www.instagram.com/vivaakam/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
              <div className="row">
                <div className="col-12">
                  <h3 className="contact-topic mb-2">Send us a message</h3>
                </div>
              </div>
              <Form >
                <Form.Group controlId="formName">
                  <Form.Label className="contact-label">
                    Enter your name *
                  </Form.Label>
                  <Form.Control
                    className="contact-textbox"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => { setName(e.target.value); }}
                    required
                  />
                  <p style={{ color: 'red' }}>{nameErr}</p>
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label className="contact-label">
                    Enter email address *
                  </Form.Label>
                  <Form.Control
                    className="contact-textbox"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }}
                    required
                  />
                  <p style={{ color: 'red' }}>{emailErr}</p>
                </Form.Group>
                <Form.Group controlId="formMessage">
                  <Form.Label className="contact-label">Message *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    value={message}
                    onChange={(e) => { setMessage(e.target.value); }}
                    required
                  />
                </Form.Group>
                <p style={{ color: 'red' }}>{messageErr}</p>
                <Button
                  className="contact-btn my-4"
                  type="submit"
                  // disabled={!recaptchaToken}
                  onClick={submitContactDetails}
                  style={{ cursor: "pointer", backgroundColor: '#9E80BC', borderRadius: '15px', border: 'none' }}
                >
                  Send a message
                </Button>
              </Form>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="cf-turnstile"
                  data-sitekey="0x4AAAAAAAGmF9RMuPj8ZtEr"
                  data-sitesecret="0x4AAAAAAAGmF3a0KfKiiAT8h8QxzNkJaQE"
                  data-callback="handleCaptchaSuccess"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ContactArea